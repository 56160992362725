import "./Tabs.css";
import { useMemo, useState } from "react";
import { Leaders } from "../Leaders/Leaders";
import { Vote } from "../Vote/Vote";
import { Friends } from "../Friends/Friends";
import { Earn } from "../Earn/Earn";

import VoteIcon from "./icons/vote.svg?react";
import LeadersIcon from "./icons/leaders.svg?react";
import FriendsIcon from "./icons/friends.svg?react";
import EarnIcon from "./icons/earn.svg?react";
import WalletIcon from "assets/wallet.svg?react";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectTabId, setTabId } from "store/settings";
import { dispatch } from "store";
import WebApp from "@twa-dev/sdk";
import { ProfileEdit } from "components/ProfileEdit/ProfileEdit";
import { Modal } from "components/Modal/Modal";
import { ProfileVoteProps } from "components/ProfileVote/ProfileVote";
import { Profile, ProfileProps } from "components/Profile/Profile";
import { Wallet } from "components/Wallet/Wallet";

const Tabs_QueryFragment = gql(`
  fragment Tabs_QueryFragment on Query {
    ...Vote_QueryFragment
    profile: user(id: $userId) {
      id
      hideProfileByRefLink
      ...ProfileUser_QueryFragment
    }
    user {
      id
      ...ProfileEditUser_QueryFragment
      ...WalletUser_QueryFragment
      ...LeadersUser_QueryFragment
    }
  }
`);

type TabsProps = {
  query: FragmentType<typeof Tabs_QueryFragment>;
  voteConfig: ProfileVoteProps["voteConfig"];
  bonus: ProfileProps["bonus"];
};

export const Tabs = (props: TabsProps) => {
  const query = getFragmentData(Tabs_QueryFragment, props.query);
  const { t } = useTranslation("Tabs");

  const items = useMemo(
    () => [
      {
        id: "vote",
        text: t("vote"),
        Icon: VoteIcon,
      },
      {
        id: "leaders",
        text: t("contest"),
        Icon: LeadersIcon,
      },
      {
        id: "friends",
        text: t("friends"),
        Icon: FriendsIcon,
      },
      {
        id: "earn",
        text: t("earn"),
        Icon: EarnIcon,
      },
      {
        id: "wallet",
        text: t("wallet"),
        Icon: WalletIcon,
      },
    ],
    [t]
  );

  const tabId = useSelector(selectTabId);

  const [profileEditVisible, setProfileEditVisible] = useState(
    WebApp.initDataUnsafe.start_param === "profileEdit"
  );

  const [profileVisible, setProfileVisible] = useState(
    Boolean(query.profile && !query.profile.hideProfileByRefLink)
  );

  if (!query) return null;

  return (
    <>
      {tabId === "vote" && (
        <Vote query={query} voteConfig={props.voteConfig} bonus={props.bonus} />
      )}
      {tabId === "leaders" && (
        <Leaders
          voteConfig={props.voteConfig}
          bonus={props.bonus}
          user={query.user}
        />
      )}
      {tabId === "friends" && (
        <Friends bonus={props.bonus} voteConfig={props.voteConfig} />
      )}
      {tabId === "earn" && <Earn />}
      {tabId === "wallet" && <Wallet user={query.user} />}
      <div className="tabs">
        {items.map(({ id, text, Icon }) => (
          <div
            key={id}
            className={`tab-item ${id === tabId ? " tab-item-selected" : ""}`}
            onClick={() => dispatch(setTabId(id))}
          >
            <Icon width={24} height={24} />
            <div className="tab-item-text">{text}</div>
          </div>
        ))}
      </div>
      {profileEditVisible && (
        <Modal fullscreen onClose={() => setProfileEditVisible(false)}>
          <ProfileEdit
            user={query.user}
            onClose={() => setProfileEditVisible(false)}
          />
        </Modal>
      )}
      {profileVisible && (
        <Modal fullscreen onClose={() => setProfileVisible(false)}>
          <Profile
            user={query.profile}
            voteConfig={props.voteConfig}
            bonus={props.bonus}
          />
        </Modal>
      )}
    </>
  );
};
